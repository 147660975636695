import * as Sentry from '@sentry/sveltekit';
import { env } from '$env/dynamic/public';
import posthog from 'posthog-js';
import { initializeAuth0Client, handleCallback, setRedirectUri } from '$lib/stores/auth';

if (env.PUBLIC_SENTRY_ENV_TAG === 'prod') {
	posthog.init('phc_NMcUhSsmev8Vb4tTXTkFE6hVG7hfmfjK016VDdm6w1N', {
		opt_in_site_apps: true,
		api_host: 'https://ph.kontali.com',
		ui_host: 'https://us.posthog.com'
	});
}

function setupErrorHandling() {
	if (window.location.hostname !== 'localhost' && window.location.port != '5173') {
		const integrations: any[] = [
			new Sentry.Replay({
				maskAllInputs: false,
				maskAllText: false,
				blockAllMedia: false,
				networkDetailAllowUrls: [window.location.origin, 'cdn.jifo.co', 'infogram.com']
			})
		];

		if (env.PUBLIC_SENTRY_ENV_TAG === 'prod') {
			integrations.push(new posthog.SentryIntegration(posthog, 'kontali', 4505277668720640));
		}
	}
	return Sentry.handleErrorWithSentry();
}

async function initialize() {
	// setupErrorHandling();
	setRedirectUri(new URLSearchParams(window.location.search));
	await initializeAuth0Client();
	await handleCallback();
}

initialize();

export const handleError = setupErrorHandling();
