import type { ParamMatcher } from '@sveltejs/kit';

export const match = ((param) => {
	const email: string = param.split('-').slice(0, -1)[0];
	const code: string = param.split('-').slice(-1)[0];

	if (email && code) {
		return true;
	}
	return false;
}) satisfies ParamMatcher;
